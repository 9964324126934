/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


/* Components */
@import './components/Button/styleButton.css';


/* Change Text Selection Color */
::-moz-selection{
   color: white;
   background: #FF5A26;
 }
 ::selection{
   color: white;
   background: #FF5A26;
 }

 .scroller {
  scrollbar-color: hotpink blue;
}